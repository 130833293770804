import { APP_VERSION, NODE_ENV, PLAYER_NAME } from './constants';
import deviceInfo from './utils/deviceInfo';

const isProd = NODE_ENV === 'production';

const displayAppVersion = () => {
  const date = new Date(); // Or the date you'd like converted.
  const isoDateTime = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000,
  ).toISOString();
  const appVersionDescriptor = isProd
    ? ''
    : `${[NODE_ENV, isoDateTime, deviceInfo(), PLAYER_NAME].join('<br>')}`;
  return `${APP_VERSION}${appVersionDescriptor}`;
};

const playerElement = document.getElementsByTagName('cast-media-player')[0];
const splashElement = document.getElementById('idleScreen');
const versionElement = document.getElementById('versionId');
const versionInfo = document.getElementById('versionInfo');
const title = document.getElementById('title');
const subtitle = document.getElementById('subtitle');
const notificationArea = document.getElementById('notificationArea');

export function setVersionSplash() {
  versionElement.innerHTML = `${displayAppVersion()}`;
}

// export const removeOpacity = () => {
//   const newStyle = Object.assign(splashElement.style, {
//     display: 'block'
//   });
//   splashElement.style = newStyle;
// };

const SUPPORTED_CHROME_VERSION_MIN = 53;
const SUPPORTED_CMAF_CHROME_VERSION_MIN = 68;

const userAgentInfo = () => {
  const regex = /Chrome\/(\d+)\.(\d+)\.(\d+)\.(\d+)\s+/;
  const [, major, minor, patch] = navigator.userAgent.match(regex) || [];
  return {
    major,
    minor,
    patch,
  };
};

const isSupported = ({ major }) => {
  return major >= SUPPORTED_CHROME_VERSION_MIN;
};

const isCurrentBrowserSupported = () => {
  const agent = userAgentInfo(navigator.userAgent);
  return isSupported(agent);
};

const isCmafSupported = () => {
  const { major = '' } = userAgentInfo();
  return major && major >= SUPPORTED_CMAF_CHROME_VERSION_MIN;
};

// TODO: This is just a workaround for the annoying spinner on old cast versions
const workAroundRestoreLoadingSpinner = () => {
  if (isCurrentBrowserSupported()) {
    return;
  }
  const style = getComputedStyle(playerElement);
  const currValue = style.getPropertyValue('--buffering-image');
  if (currValue === 'none') {
    playerElement.style.setProperty('--buffering-image', 'unset');
  }
};

// TODO: This is just a workaround for the annoying spinner on old cast versions
const workAroundRemoveLoadingSpinner = () => {
  if (isCurrentBrowserSupported()) {
    return;
  }
  playerElement.style.setProperty('--buffering-image', 'none');
};

const transitionToPlay = isLive => {
  playerElement.style.setProperty('display', 'unset');
  splashElement.style.setProperty('display', 'none');
  workAroundRestoreLoadingSpinner();
  if (isLive) {
    workAroundRemoveLoadingSpinner();
  }
};

const transitionToIdle = () => {
  playerElement.style.setProperty('display', 'none');
  splashElement.style.setProperty('display', 'unset');
  setVersionSplash();
};

const getCastPlayer = () => {
  const shadow = playerElement.shadowRoot;
  return shadow.getElementById('castPlayer');
};

const showTimeDuration = isActive => {
  const shadow = playerElement.shadowRoot;
  const progressElement = shadow.getElementById('castControlsCurTime');

  if (progressElement && progressElement.style) {
    if (isActive) {
      progressElement.style.removeProperty('display');
      return;
    }
    progressElement.style.setProperty('display', 'none');
  }
};

const removeCastPlayer = () => {
  playerElement.parentNode.removeChild(playerElement);
};

const displayUnsupported = () => {
  playerElement.style.setProperty('display', 'none');
  splashElement.style.setProperty('display', 'unset');
  versionInfo.style.setProperty('display', 'none');
  // title.innerHTML = 'Unable to Cast';
  title.innerHTML = 'Device Not Supported';
  subtitle.innerHTML = `
    Unfortunately, this Chromecast device is no longer supported as it does not provide a good streaming experience on Optus Sport.<br>
    Please use Optus Sport across our many other supported devices, including newer generations of Chromecast.
  `;
};

const displayNotificationContent = content => {
  notificationArea.innerHTML = content;
  notificationArea.style.setProperty('display', 'block');
};

export {
  playerElement,
  isSupported,
  userAgentInfo,
  getCastPlayer,
  removeCastPlayer,
  transitionToIdle,
  transitionToPlay,
  isCurrentBrowserSupported,
  isCmafSupported,
  showTimeDuration,
  workAroundRemoveLoadingSpinner,
  workAroundRestoreLoadingSpinner,
  displayUnsupported,
  displayNotificationContent,
};
